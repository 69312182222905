import { COMMENT_BASE_FRAGMENT } from '@/graphql/_Fragments/Comment/Base';

export const COMMENT_FULL_FRAGMENT = `
  fragment commentFullFragment on Comment {
    uid
    textContent
    parentComment {
        uid
        textContent
        parentComment{
            uid
            textContent
            parentComment{
                uid
                textContent
            }
        }
    }
    createdTime
    updatedTime
    createdTimestamp
    updatedTimestamp
    likeUsers {
        uid
        firstName
        lastName
        name
        pictureFileResource{
            schemaCode
            path
        }
    }
    author {
        uid
        firstName
        lastName
        name
        jobTitle
        employerName
        pictureFileResource {
            schemaCode
            path
        }
        bannerFileResource {
            schemaCode
            path
        }
    }
    childrenComments (orderBy:createdTime_asc) {
        ...commentBaseFragment
        childrenComments (orderBy:createdTime_asc) {
            ...commentBaseFragment
        }
    }
    _isLiked(myUid: "%authUser%")
    _likesCount
    userDeleted
  }
  ${COMMENT_BASE_FRAGMENT}
`;
